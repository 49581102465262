import styled from "styled-components"
import colors from "shared/utils/colors"
import { sm, md } from "mill/utils/breakpoints"

export const Container = styled.div`
  margin: 0 auto;
  padding-top: 3rem;
  position: relative;

  > div {
    max-width: 60rem;
    margin: 0 auto;
  }

  @media ${md} {
    min-height: 100vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: row-start;
    padding-top: 0;
    flex: 1;
  }
`

export const YarnoLogo = styled.img`
  position: absolute;
  top: 3rem;
  left: 3rem;
  max-height: 3rem;
`

export const Heading = styled.div`
  color: ${props => props.theme.colors.primary};

  h1 {
    text-transform: uppercase;
  }

  p {
    font-size: 2.1rem;
  }

  @media ${sm} {
    margin-bottom: 0;
  }
`

export const Explanation = styled.div`
  padding-top: 1rem;

  h3 {
    text-transform: uppercase;
    color: ${colors.grayMatterhorn};
    font-family: "Raleway", sans-serif;
    font-size: 1.5rem;
  }

  p {
    color: ${colors.grayDim};
  }

  a,
  span {
    display: block;
    margin: 2rem auto 0 auto;
  }

  @media ${sm} {
    padding-top: 0;

    a,
    span {
      display: inline-block;
      margin: 2rem 0 0 0;
    }

    a {
      margin-right: 2rem;
    }
  }
`

export const ImageContainer = styled.div`
  text-align: center;

  img {
    max-width: 20rem;
    max-height: 30rem;
    margin-right: 2rem;
    margin-top: 4rem;
  }

  @media ${sm} {
    img {
      max-width: 25rem;
    }
  }

  @media ${md} {
    min-width: 50%;
    display: flex;
    align-items: center;
    margin-left: 5rem;
    flex: 1;

    img {
      max-width: none;
      max-height: none;
      margin-top: 0;
    }
  }
`

export const ContentContainer = styled.div`
  padding: 0 3rem 3rem 3rem;

  @media ${md} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5rem;
    padding-left: 8rem;
  }
`
